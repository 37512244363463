import { Ref, ref } from "vue";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

/**
 * @param pdfLink is ref / computed variable
 * @returns
 *   data: Ref<any | null> ,
 *   pending: Ref<boolean> ,
 *   execute: () => Promise<void> ,
 *   error: Ref<any | null> ,
 *   reset: () => void ,
 */
export const usePdfJS = (pdfLink: Ref) => {
  const fetchData = ref(null as any);
  const fetchPending = ref(false);
  const fetchError = ref(null as any);

  const execute = async () => {
    fetchPending.value = true;

    GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const loadingTask = getDocument(pdfLink.value);

    try {
      fetchData.value = await loadingTask.promise;
      fetchError.value = null;
    } catch (err) {
      fetchError.value = err;
      fetchData.value = null;
    } finally {
      fetchPending.value = false;
    }
  };

  const fetchReset = () => {
    fetchData.value = null;
    fetchPending.value = false;
    fetchError.value = null;
  };

  return {
    data: fetchData,
    pending: fetchPending,
    execute,
    error: fetchError,
    reset: fetchReset,
  };
};

export const renderPdfPage = async (
  {
    pdf,
    pageIndex,
    canvas,
    scale = 1,
  }: {
    pdf: any;
    pageIndex: number;
    canvas: HTMLCanvasElement;
    scale?: number;
  },
  {
    signal,
  }: {
    signal?: any;
  } = {}
) => {
  const pdfState = pdf;

  // Fetch the first page
  const page = await pdfState?.getPage(pageIndex);

  if (signal?.aborted) {
    return;
  }

  const viewport = page.getViewport({ scale });

  // Support HiDPI-screens.
  const outputScale = window.devicePixelRatio || 1;

  // Prepare canvas using PDF page dimensions
  const ctx = canvas.getContext("2d");

  canvas.width = Math.floor(viewport.width * outputScale);
  canvas.height = Math.floor(viewport.height * outputScale);

  const transform =
    outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

  page.render({
    canvasContext: ctx,
    viewport: viewport,
    transform,
  });
};

export const downloadFilePdf = async (fileUrl: string) => {
  const fileUrlSplitted = fileUrl.split("/");
  const fileName = fileUrlSplitted[fileUrlSplitted.length - 1];

  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const anchorForDownload = document.createElement("a");
  anchorForDownload.href = URL.createObjectURL(blob);
  anchorForDownload.setAttribute("download", fileName);
  anchorForDownload.click();
  URL.revokeObjectURL(anchorForDownload.href);
};
