
import { computed, defineComponent, ref, watch } from "vue";
import { renderPdfPage } from "@/utils/pdf-viewer";

export default defineComponent({
  name: "CertificatePopupMainThumbnail",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    pdfState: {
      type: Object,
      default: () => ({}),
    },
    pdfPages: {
      type: Number,
      default: () => 1,
    },
    currentPage: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props) {
    const canvasElement = ref([]);
    const pdfState = computed(() => props.pdfState);
    const currentScaleZoom = ref(1);

    const zoomIn = () => {
      currentScaleZoom.value += 0.1;
    };

    const zoomOut = () => {
      currentScaleZoom.value -= 0.1;
    };

    watch(
      [pdfState, canvasElement, currentScaleZoom],
      ([pdf, canvasEl, scaleZoom], _, onInvalidate) => {
        if (!pdf || !canvasEl) {
          return;
        }

        const controller = new AbortController();
        onInvalidate(() => {
          controller.abort();
        });

        canvasEl?.map((canvas, idx) => {
          const pageIndex = idx + 1;
          renderPdfPage(
            { pdf, pageIndex, canvas, scale: scaleZoom },
            { signal: controller.signal }
          );
        });
      },
      {
        immediate: true,
      }
    );

    return {
      canvasElement,
      zoomIn,
      zoomOut,
    };
  },
});
