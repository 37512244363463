
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { LearningMateri } from "@/services/v1/member/learning/types";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { downloadFilePdf, renderPdfPage, usePdfJS } from "@/utils/pdf-viewer";
import { getMemberLearningView } from "@/services/v1/member/learning";
import PdfMainThumbnail from "./main-thumbnail.vue";

export default defineComponent({
  components: {
    PdfMainThumbnail,
  },
  setup() {
    // START === setup breadcrumb =====================================================
    const route = useRoute();
    const slug = route.params?.slug || "";

    const pathMemberMateri = `/member/materi`;
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Materi Pembelajaran",
        to: pathMemberMateri,
      },
      {
        title: "Materi e-Book",
        to: pathMemberMateri,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    // START === Function for get data ebook ============================================
    const dataEbook = ref({} as LearningMateri);
    const ebookLoading = ref(false as boolean);

    const getDataEbook = async () => {
      ebookLoading.value = true;

      try {
        const hit = await getMemberLearningView(slug as string);

        const resData = hit?.response;

        console.log("resData getDataEbook ", resData);

        dataEbook.value = resData;
      } catch (err: any) {
        console.error("err getDataEbook ", err, err?.response);
      } finally {
        ebookLoading.value = false;
      }
    };

    const heightPdf = ref(100);
    const setupHeightPdf = () => {
      const heightContent = document.getElementsByClassName("content")[0]
        .clientHeight;

      heightPdf.value = heightContent - 210;
    };

    onMounted(() => {
      getDataEbook();
      setupHeightPdf();
    });
    // END === Function for get data ebook ==============================================

    // START === handle show pdf-viewer =================================================
    const pdfLink = computed(() => dataEbook.value?.content);
    const {
      data: dataPdfViewer,
      pending: pendingPdfViewer,
      error: errorPdfViewer,
      execute: executePdfViewer,
    } = usePdfJS(pdfLink);

    const currentPdfPage = ref(1);
    const canvasElement = ref();
    const pdfPages = computed(() => dataPdfViewer.value?.numPages || 0);

    watch(
      [dataPdfViewer, currentPdfPage],
      ([pdf, pageIndex], _, onInvalidate) => {
        const canvas = canvasElement.value;
        if (!canvas || !pdf) {
          return;
        }

        const controller = new AbortController();
        onInvalidate(() => {
          controller.abort();
        });

        renderPdfPage(
          { pdf, pageIndex, canvas },
          { signal: controller.signal }
        );
      }
    );

    watch(pdfLink, (newVal) => {
      if (newVal) executePdfViewer();
    });
    // END === handle show pdf-viewer ===================================================

    return {
      dataEbook,
      ebookLoading,
      heightPdf,
      downloadFilePdf,
      pdfPages,
      pendingPdfViewer,
      dataPdfViewer,
      currentPdfPage,
    };
  },
});
